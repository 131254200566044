<template>
  <v-container
    v-show="loadData && businessData"
    :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }"
  >
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Business
        </h3>
      </v-col>
      <v-col cols="12" class="py-1">
        <v-card class="card_background">
          <app-business-info
            ref="business_info"
            :changeDetected="onChangeDetected"
            :businessNameProp="businessData.name"
            :businessMailProp="businessData.mail"
            :businessHours="businessData.business_hours_data"
            :businessHoursSelected="businessData.business_hours"
            :categorieSelectedProp="businessData.categorie"
            :phoneProp="businessData.phone"
          ></app-business-info>
        </v-card>
      </v-col>
      <v-col cols="12" class="py-1">
        <v-card class="card_background mt-2">
          <app-business-address
            ref="business_address"
            :changeDetected="onChangeDetected"
            :locationProp="businessData.location"
          ></app-business-address>
        </v-card>
      </v-col>
    </v-row>

    <v-scroll-y-reverse-transition leave-absolute>
      <app-save-changes
        v-if="wereChangesDetected"
        :saveChanges="saveChanges"
        :discardChanges="discardChanges"
      ></app-save-changes>
    </v-scroll-y-reverse-transition>
  </v-container>
</template>

<script>
import BusinessInfo from "./BusinessDetail";
import BusinessAddress from "./BusinessAddress";
import SaveChanges from "@/components/app/SaveChanges";
import { mapActions, mapGetters, mapState } from "vuex";
import { loaderDone, loaderStart } from "@/services/LoaderService";

export default {
  data() {
    return {
      changesLog: {},
      loadData: false
    };
  },
  async mounted() {
    loaderStart();
    try {
      await this.getBusinessData();
      this.loadData = true;
      loaderDone();
    } catch {
      this.loadData = true;
      loaderDone();
    }
  },
  methods: {
    ...mapActions("app", ["statusChangesDetected"]),
    ...mapActions("settings", ["changeTheme"]),
    ...mapActions("settings", ["updateBusinessInfo"]),
    ...mapActions("business", ["getBusinessData", "updateBusinessData"]),
    onChangeDetected(data, section) {
      this.statusChangesDetected(true);
      this.changesLog[section] = data;
    },
    discardChanges() {
      this.changesLog = {};
      this.$refs.business_info.resetData();
      this.$refs.business_address.resetData();
      this.$vuetify.theme.dark == true
        ? (this.themeMode = 1)
        : (this.themeMode = 0);
      this.statusChangesDetected(false);
    },
    saveChanges() {
      if (this.changesLog["business-info"]) {
        this.updateBusinessData({ ...this.changesLog["business-info"] });
      }
      if (this.changesLog["business-address"]) {
        this.updateBusinessData({ ...this.changesLog["business-address"] });
      }
      let data = this.changesLog.theme;

      if (data === 0) {
        this.$vuetify.theme.dark = false;
        this.changeTheme(false);
      }
      if (data === 1) {
        this.$vuetify.theme.dark = true;
        this.changeTheme(true);
      }

      this.changesLog = {};
      this.statusChangesDetected(false);
    }
  },
  computed: {
    ...mapGetters("app", ["wereChangesDetected"]),
    ...mapState("business", ["businessData"])
  },
  beforeDestroy() {
    this.statusChangesDetected(false);
  },
  components: {
    AppBusinessInfo: BusinessInfo,
    AppBusinessAddress: BusinessAddress,
    AppSaveChanges: SaveChanges
  }
};
</script>

<style scoped>
.link-question {
  cursor: pointer !important;
  text-decoration: underline !important;
}
.link-question:hover {
  color: #455a64 !important;
}
</style>
