<template>
  <v-container>
    <h3 class="text-subtitle-1">Detalle del negocio</h3>
    <p class="caption">
      Tus clientes usarán información para contactarte
    </p>
    <v-row>
      <v-col cols="12">
        <v-text-field
          outlined
          type="text"
          ref="name"
          v-model="form.name"
          :rules="[requiredRules]"
          hide-details
          label="Nombre del negocio"
          @input="changeDetected(form, 'business-info')"
          @keyup.enter.native="$refs.mail.focus()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6" xl="6">
        <v-text-field
          outlined
          type="email"
          ref="mail"
          v-model="form.mail"
          :rules="[requiredRules, emailValidRules]"
          hide-details
          label="Correo de contacto del negocio"
          @input="changeDetected(form, 'business-info')"
          @keyup.enter.native="$refs.categories.focus()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6" xl="6">
        <v-select
          v-model="categorieSelected"
          :rules="[requiredRules]"
          ref="categories"
          item-text="categorie"
          item-value="value"
          :items="categories"
          label="Categoria"
          @input="changeDetected(form, 'business-info')"
          hide-details
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6" xl="6">
        <v-text-field
          outlined
          type="tel"
          ref="phoneBusiness"
          v-model="form.phoneBusiness"
          :rules="[requiredRules]"
          label="Telefono"
          @input="changeDetected(form, 'business-info')"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="pt-0 pb-4" ref="daysSelected">
        <p class="caption mb-0">
          Días de la semana y horarios en los que tu negocio labora
        </p>
        <v-row class="d-flex justify-center mt-2">
          <v-item-group
            multiple
            v-model="daysSelected"
            @change="changeDetected(form, 'business-info')"
          >
            <v-item
              v-for="day in days"
              :key="day.day"
              v-slot="{ active, toggle }"
            >
              <v-chip
                active-class="primary"
                :input-value="active"
                @click="toggle"
                class="mr-1"
              >
                {{ day.letter }}
              </v-chip>
            </v-item>
          </v-item-group>
        </v-row>
        <div class="text-center mt-2">
          Horario
        </div>
        <v-row>
          <v-col cols="6" class="pb-0">
            <v-menu
              ref="openTimeMenu"
              v-model="openTimeMenu"
              :close-on-content-click="false"
              :return-value.sync="openTime"
              bottom
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="openTime"
                  hide-details
                  label="Apertura"
                  :rules="[requiredRules]"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="openTimeMenu"
                v-model="openTime"
                @click:minute="
                  $refs.openTimeMenu.save(openTime);
                  changeDetected(form, 'business-info');
                "
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" class="pb-0">
            <v-menu
              ref="closeTimeMenu"
              v-model="closeTimeMenu"
              :close-on-content-click="false"
              :return-value.sync="closeTime"
              bottom
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="closeTime"
                  :rules="[requiredRules]"
                  @change="changeDetected(form, 'business-info')"
                  hide-details
                  label="Cierre"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="closeTimeMenu"
                v-model="closeTime"
                @click:minute="
                  $refs.closeTimeMenu.save(closeTime);
                  changeDetected(form, 'business-info');
                "
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, emailValid } from "@/services/ValidatorService";

export default {
  data() {
    return {
      requiredRules: required,
      emailValidRules: emailValid,
      openTimeMenu: false,
      closeTimeMenu: false,
      daysSelected: this.businessHours.days,
      openTime: this.businessHours.open,
      closeTime: this.businessHours.close,
      categorieSelected: { value: this.categorieSelectedProp },
      form: {
        name: this.businessNameProp,
        mail: this.businessMailProp,
        categorie: this.categorieSelectedProp,
        business_hours_data: this.businessHours,
        business_hours: this.businessHoursSelected,
        phoneBusiness: this.phoneProp
      },
      categories: [
        { categorie: "Belleza", value: "beauty" },
        { categorie: "Indumentaria", value: "clothing" },
        { categorie: "Electrónica", value: "electronics" },
        { categorie: "Muebles", value: "furniture" },
        { categorie: "Víveres", value: "groceries" },
        { categorie: "Artesanías", value: "handcrafts" },
        { categorie: "Joyería", value: "jewelry" },
        { categorie: "pinturas", value: "painting" },
        { categorie: "fotografía", value: "photography" },
        { categorie: "Restaurantes", value: "restaurants" },
        { categorie: "Otros alimentos y bebidas", value: "other_food_drink" },
        { categorie: "Servicios", value: "services" },
        { categorie: "Deportes", value: "sports" },
        { categorie: "Juguetes", value: "toys" },
        { categorie: "Servicios virtuales", value: "virtual_services" },
        { categorie: "Otro", value: "other" }
      ],
      days: [
        { day: "monday", letter: "L", value: 0 },
        { day: "tuesday", letter: "M", value: 1 },
        { day: "wednesday", letter: "M", value: 2 },
        { day: "thursday", letter: "J", value: 3 },
        { day: "friday", letter: "V", value: 4 },
        { day: "saturday", letter: "S", value: 5 },
        { day: "sunday", letter: "D", value: 6 }
      ]
    };
  },
  watch: {
    categorieSelected(newVal) {
      if (newVal) {
        this.form.categorie = newVal;
      }
    },
    daysSelected(newVal) {
      if (newVal) {
        this.updateBusinessHours();
        this.updateBusinessHoursData();
      }
    },
    openTime(newVal) {
      if (newVal) {
        this.updateBusinessHours();
        this.updateBusinessHoursData();
      }
    },
    closeTime(newVal) {
      if (newVal) {
        this.updateBusinessHours();
        this.updateBusinessHoursData();
      }
    }
  },
  methods: {
    resetData() {
      (this.daysSelected = this.businessHours.days),
        (this.openTime = this.businessHours.open),
        (this.closeTime = this.businessHours.close),
        (this.form = {
          name: this.businessNameProp,
          mail: this.businessMailProp,
          categorie: this.categorieSelectedProp,
          phoneBusiness: this.phoneProp
        });
    },
    updateBusinessHours() {
      this.form.business_hours = {};
      this.days.forEach(day => {
        if (this.daysSelected.includes(day.value)) {
          this.form.business_hours[day.day] = [
            {
              open: this.openTime,
              close: this.closeTime
            }
          ];
        }
      });
    },
    updateBusinessHoursData() {
      this.business_hours_data = {
        days: this.daysSelected,
        open: this.openTime,
        close: this.closeTime
      };
    }
  },
  props: {
    businessNameProp: {
      type: String,
      default: ""
    },
    businessMailProp: {
      type: String,
      default: ""
    },
    categorieSelectedProp: {
      type: String,
      default: ""
    },
    businessHours: {
      type: Object,
      default: () => ({
        days: [],
        open: "",
        close: ""
      })
    },
    phoneProp: {
      default: "",
      type: String
    },
    businessHoursSelected: {
      type: Object,
      default: () => ({
        days: [],
        open: "",
        close: ""
      })
    },
    changeDetected: {
      type: Function
    }
  }
};
</script>
