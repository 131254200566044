<template>
  <v-container>
    <h3 class="text-subtitle-1">Dirección del negocio</h3>
    <p class="caption mb-0">
      Esta dirección es la ubicación principal de tu negocio, ingresa la
      ubicación correspondiente.
    </p>
    <v-row>
      <v-col cols="12">
        <v-text-field
          id="ship-address"
          outlined
          hide-details
          autocomplete="off"
          @change="changeDetected(form, 'business-address')"
          v-model="form.location.full_address"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-expand-transition>
          <v-row v-show="form.location.full_address">
            <v-col cols="12" class="pt-0">
              <AppMapDraggableMarker :myLatlng="form.location.map_point" />
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                type="text"
                ref="street"
                v-model="form.location.street_name"
                :rules="[requiredRules]"
                label="Calle"
                @input="changeDetected(form, 'business-address')"
                @keyup.enter.native="$refs.reference.focus()"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                type="text"
                ref="reference"
                v-model="form.location.reference"
                :rules="[requiredRules]"
                label="Referencias"
                @input="changeDetected(form, 'business-address')"
                @keyup.enter.native="$refs.postalCode.focus()"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6" xl="6">
              <v-text-field
                outlined
                type="number"
                ref="postalCode"
                v-model="form.location.postal_code"
                :rules="[requiredRules]"
                label="Código postal"
                @input="changeDetected(form, 'business-address')"
                @keyup.enter.native="$refs.city.focus()"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6" xl="6">
              <v-text-field
                outlined
                type="text"
                ref="city"
                v-model="form.location.city_name"
                :rules="[requiredRules]"
                label="Ciudad"
                @input="changeDetected(form, 'business-address')"
                @keyup.enter.native="$refs.state.focus()"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6" xl="6">
              <v-select
                v-model="form.location.state_name"
                :rules="[requiredRules]"
                ref="state"
                :items="getStates"
                label="Estado"
                outlined
                hide-details
                @input="changeDetected(form, 'business-address')"
              ></v-select>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required } from "@/services/ValidatorService";
import { Loader } from "@googlemaps/js-api-loader";
import { mapActions, mapGetters, mapState } from "vuex";
import { app } from "@/config/globals";
import MapDraggableMarker from "@/components/app/MapDraggableMarker";
let autocomplete;

export default {
  data() {
    return {
      requiredRules: required,
      form: {
        location: this.locationProp
      }
    };
  },
  mounted() {
    this.initMapLoader();
  },
  watch: {
    businessLocationLatLng(newLatLng) {
      if (newLatLng) {
        this.form.location.map_point = newLatLng;
        this.changeDetected(this.form, "business-address");
      }
    }
  },
  computed: {
    ...mapState("business", ["businessLocationLatLng"]),
    ...mapGetters("app", ["getStates"])
  },
  methods: {
    ...mapActions("business", ["setLocationLatLng"]),
    resetData() {
      this.form = {
        location: this.locationProp
      };
    },
    async initMapLoader() {
      const fieldContainer = document.querySelector("#ship-address");
      const loader = new Loader({
        apiKey: app.maps_api_key,
        version: "weekly",
        libraries: ["places"]
      });
      // eslint-disable-next-line
      if (typeof google === "object" && typeof google.maps === "object") {
        // eslint-disable-next-line
        autocomplete = new google.maps.places.Autocomplete(fieldContainer, {
          componentRestrictions: { country: ["mx"] },
          fields: ["address_components", "geometry"],
          types: ["address"]
        });
      } else {
        await loader.load();
        // eslint-disable-next-line
        autocomplete = new google.maps.places.Autocomplete(fieldContainer, {
          componentRestrictions: { country: ["mx"] },
          fields: ["address_components", "geometry"],
          types: ["address"]
        });
      }
      autocomplete.addListener("place_changed", this.fillInAddress);
    },
    fillInAddress() {
      // Get the place details from the autocomplete object.
      const place = autocomplete.getPlace();
      this.form.location.full_address = "";
      for (const placeAddress of place.address_components) {
        const placeType = placeAddress.types[0];
        switch (placeType) {
          case "route": {
            this.form.location.street_name = placeAddress.long_name;
            this.form.location.full_address += placeAddress.long_name + ", ";
            break;
          }
          case "postal_code": {
            this.form.location.postal_code = placeAddress.long_name;
            break;
          }
          case "sublocality_level_1": {
            this.form.location.full_address += placeAddress.long_name + ", ";
            break;
          }
          case "locality": {
            this.form.location.city_name = placeAddress.long_name;
            this.form.location.full_address += placeAddress.short_name + ", ";
            break;
          }
          case "administrative_area_level_1": {
            this.form.location.state_name = placeAddress.long_name;
            this.form.location.full_address += placeAddress.short_name;
            break;
          }
        }
      }
      const lat = autocomplete.getPlace().geometry.location.lat();
      const lng = autocomplete.getPlace().geometry.location.lng();
      this.setLocationLatLng({ lat, lng });
    }
  },
  components: {
    AppMapDraggableMarker: MapDraggableMarker
  },
  props: {
    locationProp: {
      default: () => ({}),
      type: Object
    },
    changeDetected: {
      type: Function
    }
  }
};
</script>
